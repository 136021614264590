@charset "UTF-8";
@font-face {
  font-family: "icons";
  src: url("/assets/fonts/iconfont/icons.eot");
  src: url("/assets/fonts/iconfont/icons.eot?#iefix") format("eot"), url("/assets/fonts/iconfont/icons.woff") format("woff"), url("/assets/fonts/iconfont/icons.ttf") format("truetype"), url("/assets/fonts/iconfont/icons.svg#icons") format("svg");
  font-weight: normal;
  font-style: normal;
}
/*============================================================
  pageID-top
============================================================*/
.pageID-top {
  /*----------------------------------------------------------------------*/
  /*============================================================
    共通部分調整
  ============================================================*/
  /*  gHeader
  ----------------------------------------------------------------------*/
  /*  gNav
  ----------------------------------------------------------------------*/
  /* gDrop
  ----------------------------------------------------------------------*/
  /*  gService
  ----------------------------------------------------------------------*/
  /*  gRecruit
  ----------------------------------------------------------------------*/
  /*  gSitemap
  ----------------------------------------------------------------------*/
  /*  footerLinks
  ----------------------------------------------------------------------*/
  /*  .footerCorp
  ----------------------------------------------------------------------*/
  /*  .footerGroup
  ----------------------------------------------------------------------*/
  /*  copyright
  ----------------------------------------------------------------------*/
  /*============================================================
    mainImg
  ============================================================*/
  /*============================================================
    .topAbout
  ============================================================*/
  /*============================================================
  .topProject
  ============================================================*/
  /*============================================================
    topNews
  ============================================================*/
  /*============================================================
    pages調整
  ============================================================*/
  /*----------------------------------------------------------------------*/
}
.pageID-top .wrapper {
  min-width: 0;
}
.pageID-top .contents {
  width: auto;
  margin: 0;
  display: block;
}
@media all and (-ms-high-contrast: none) {
  .pageID-top .wrapper {
    min-width: 1400px;
  }
}
@media screen and (max-width: 834px) {
  .pageID-top .contents {
    padding: 0;
  }
}
@media all and (-ms-high-contrast: none) and (max-width: 834px) {
  .pageID-top .wrapper {
    min-width: 0;
  }
}
@media screen and (min-width: 835px) {
  .pageID-top .gHeader {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 2000;
  }
  .pageID-top .gHeader_inner {
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&1+0,0+100 */
    background: -moz-linear-gradient(top, white 0%, rgba(255, 255, 255, 0) 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(top, white 0%, rgba(255, 255, 255, 0) 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, white 0%, rgba(255, 255, 255, 0) 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#ffffff", endColorstr="#00ffffff",GradientType=0 );
    /* IE6-9 */
    min-width: 0;
  }
  .pageID-top .gHeader_logo {
    position: relative;
    z-index: 100;
  }
}
@media screen and (min-width: 835px) {
  .pageID-top .gNav {
    margin: 0;
  }
  .pageID-top .gNav_name {
    font-size: min(calc(100vw/70), 18px);
    padding: 0 min(calc(100vw/100), 25px);
  }
  .pageID-top .gNav_head {
    position: relative;
    z-index: 100;
  }
  .pageID-top .gNav_contact {
    flex-grow: 1;
    width: auto;
    max-width: 180px;
    position: relative;
    z-index: 100;
  }
  .pageID-top .gNav_lang {
    font-size: min(calc(100vw/100), 16px);
    padding: 0 min(calc(100vw/100), 10px);
  }
}
@media all and (-ms-high-contrast: none) {
  .pageID-top .gNav_contact {
    width: 180px !important;
  }
}
@media screen and (min-width: 835px) {
  .pageID-top .gDrop {
    top: 0;
  }
  .pageID-top .gDrop_inner {
    padding-top: 100px;
  }
}
@media screen and (max-width: 1140px) and (min-width: 835px) {
  .pageID-top .gDrop_inner {
    width: auto;
  }
  .pageID-top .gDrop_index {
    width: 28vw;
    padding: 80px calc(20/1140 * 100vw);
    margin: 0;
  }
  .pageID-top .gDrop_indexImg {
    margin-right: calc(90/1140 * -100vw);
  }
  .pageID-top .gDrop_body {
    width: 72vw;
    padding: 80px calc(20/1140 * 100vw) 80px 0;
  }
  .pageID-top .gDrop_list {
    margin-left: calc(110/1140 * 100vw);
  }
  .pageID-top .gDrop_item {
    margin-left: calc(60/1140 * 100vw);
  }
  .pageID-top .gDrop_title:before {
    font-size: calc(40/1140 * 100vw);
  }
}
@media all and (-ms-high-contrast: none) {
  .pageID-top .gDrop:before {
    top: 100px;
  }
}
@media screen and (min-width: 835px) {
  .pageID-top .gService {
    padding-left: 20px;
    padding-right: 20px;
  }
  .pageID-top .gService_inner {
    width: auto;
  }
}
@media screen and (min-width: 835px) {
  .pageID-top .gRecruit_inner {
    width: auto;
    padding-left: 20px;
    padding-right: 20px;
  }
  .pageID-top .gRecruit_header {
    margin: 0 auto;
    max-width: 1100px;
  }
  .pageID-top .gRecruit_box {
    width: calc(100%/2 - 60px);
    max-width: 520px;
  }
}
@media screen and (min-width: 835px) {
  .pageID-top .gSitemap_inner {
    width: auto;
    max-width: 1140px;
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media screen and (min-width: 835px) {
  .pageID-top .footerLinks_inner {
    width: auto;
    max-width: 1100px;
  }
}
@media screen and (min-width: 835px) {
  .pageID-top .footerCorp_inner {
    width: auto;
    max-width: 1100px;
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media screen and (min-width: 835px) {
  .pageID-top .footerGroup_inner {
    width: auto;
    max-width: 1100px;
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media screen and (min-width: 835px) {
  .pageID-top .copyright_inner {
    width: auto;
    max-width: 1100px;
    padding-left: 20px;
    padding-right: 20px;
  }
}
.pageID-top .mainImg {
  position: relative;
  z-index: 1;
}
.pageID-top .mainImg_bg {
  margin: 0;
  position: relative;
}
.pageID-top .mainImg_bg img,
.pageID-top .mainImg_bg video {
  width: 100%;
  object-fit: cover;
  min-height: 620px;
  max-height: 100vh;
  vertical-align: bottom;
}
.pageID-top .mainImg_box {
  background: rgba(255, 255, 255, 0.7);
  min-width: 800px;
  padding: 60px;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
}
.pageID-top .mainImg_box > :first-child {
  margin-top: 0;
}
.pageID-top .mainImg_catchcopy {
  font-size: 1.75rem;
  line-height: 1;
  color: #ec6c00;
  font-family: "Noto Serif JP", serif;
  letter-spacing: 0.3em;
  font-weight: bold;
}
.pageID-top .mainImg_title {
  margin: 40px 0 0;
  line-height: 1;
  font-family: "Noto Serif JP", serif;
  font-size: 4.375rem;
}
.pageID-top .mainImg_title > span {
  color: #ec6c00;
}
.pageID-top .mainImg_txt {
  margin: 40px 0 0;
  font-size: 20px;
  font-family: "Noto Serif JP", serif;
  font-weight: bold;
}
.pageID-top .mainImg_scroll {
  position: absolute;
  bottom: 0;
  left: 50%;
  margin: 0 0 0 -550px;
}
@media all and (-ms-high-contrast: none) {
  .pageID-top .mainImg_bg {
    margin: 0;
    padding-top: 56.25%;
    position: relative;
  }
  .pageID-top .mainImg_bg img,
.pageID-top .mainImg_bg video {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
  }
}
@media screen and (max-width: 834px) {
  .pageID-top .mainImg {
    min-height: calc(100vh - 56px);
    padding: 20px;
    display: flex;
    display: -webkit-flex;
    justify-content: center;
    align-items: center;
  }
  .pageID-top .mainImg_bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
  }
  .pageID-top .mainImg_bg video,
.pageID-top .mainImg_bg img {
    min-height: 0;
    max-height: none;
    height: 100%;
  }
  .pageID-top .mainImg_box {
    min-width: 0;
    position: static;
    transform: translate(0);
    padding: 20px;
  }
  .pageID-top .mainImg_catchcopy {
    font-size: calc((100vw - 80px)/15);
  }
  .pageID-top .mainImg_title {
    margin: 20px 0 0;
    font-size: calc((100vw - 80px)/8);
  }
  .pageID-top .mainImg_txt {
    margin: 20px 0 0;
    font-size: calc((100vw - 80px)/15);
  }
}
.pageID-top .topAbout {
  display: flex;
  display: -webkit-flex;
  justify-content: center;
}
.pageID-top .topAbout_bg {
  order: 1;
  flex-grow: 0;
  flex-shrink: 0;
  width: calc(760/1920 * 100vw);
  margin: 0;
}
.pageID-top .topAbout_bg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.pageID-top .topAbout_contents {
  order: 0;
  width: calc(1160/1920 * 100vw);
  padding-top: min(100px, 7.8431372549vw);
  padding-bottom: min(180px, 14.1176470588vw);
  position: relative;
}
.pageID-top .topAbout_contents:before {
  content: "COMPANY";
  font-family: "Noto Serif JP", serif;
  font-weight: bold;
  color: #f6f6f6;
  line-height: 1;
  font-size: min(180px, 14.1176470588vw);
  position: absolute;
  bottom: 0;
  right: min(120px, 9.4117647059vw);
  white-space: nowrap;
}
.pageID-top .topAbout_inner {
  max-width: calc(100% - 20px);
  width: 750px;
  margin-left: auto;
}
.pageID-top .topAbout_title {
  font-family: "Noto Serif JP", serif;
  color: #ec6c00;
  font-weight: bold;
  line-height: 1;
  font-size: min(16px, 1.2549019608vw);
}
.pageID-top .topAbout_en {
  margin: 0;
  font-family: "Noto Serif JP", serif;
  color: #ec6c00;
  font-weight: bold;
  line-height: 1;
  font-size: min(74px, 5.8039215686vw);
  position: relative;
}
.pageID-top .topAbout_en:after {
  content: "";
  display: block;
  max-width: 280px;
  width: 21.9607843137vw;
  height: 2px;
  background: #333;
  position: absolute;
  top: 50%;
  right: 0;
  margin-top: -1px;
}
.pageID-top .topAbout_body {
  max-width: 630px;
  margin-top: min(60px, 4.7058823529vw);
  margin-right: 20px;
}
.pageID-top .topAbout_body > :first-child {
  margin-top: 0;
}
.pageID-top .topAbout_lead {
  font-family: "Noto Serif JP", serif;
  font-size: min(24px, 1.8823529412vw);
  line-height: 2;
  font-weight: bold;
}
.pageID-top .topAbout_txt {
  margin-top: min(40px, 3.137254902vw);
  font-size: min(16px, 1.2549019608vw);
  line-height: 2;
}
.pageID-top .topAbout_btn {
  margin-top: min(70px, 5.4901960784vw);
  max-width: 280px;
  width: 21.9607843137vw;
}
@media all and (-ms-high-contrast: none) {
  .pageID-top .topAbout_bg {
    min-width: calc(1400px - 750px);
  }
  .pageID-top .topAbout_contents {
    min-width: 750px;
    padding: 100px 0 180px;
  }
  .pageID-top .topAbout_contents:before {
    font-size: 180px;
    right: 120px;
  }
  .pageID-top .topAbout_inner {
    max-width: none;
    padding-left: 20px;
  }
  .pageID-top .topAbout_title {
    font-size: 16px;
  }
  .pageID-top .topAbout_en {
    font-size: 74px;
  }
  .pageID-top .topAbout_body {
    margin-top: 60px;
  }
  .pageID-top .topAbout_lead {
    font-size: 24px;
  }
  .pageID-top .topAbout_txt {
    margin-top: 40px;
    font-size: 16px;
  }
  .pageID-top .topAbout_btn {
    margin-top: 70px;
  }
}
@media screen and (max-width: 834px) {
  .pageID-top .topAbout {
    display: block;
    position: relative;
  }
  .pageID-top .topAbout_bg {
    width: auto;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    opacity: 0.3;
  }
  .pageID-top .topAbout_contents {
    width: auto;
    padding: 30px 10px;
  }
  .pageID-top .topAbout_contents:before {
    content: none;
  }
  .pageID-top .topAbout_inner {
    width: auto;
    max-width: none;
  }
  .pageID-top .topAbout_head {
    display: flex;
    display: -webkit-flex;
    align-items: flex-end;
  }
  .pageID-top .topAbout_title {
    order: 1;
    margin: 0 0 0 0.5em;
    font-size: 1.1428571429rem;
    line-height: 1.2;
    text-shadow: 1px 1px 0 #fff, -1px 1px 0 #fff, 1px -1px 0 #fff, -1px -1px 0 #fff;
  }
  .pageID-top .topAbout_en {
    order: 0;
    font-size: 2.1428571429rem;
    text-shadow: 1px 1px 0 #fff, -1px 1px 0 #fff, 1px -1px 0 #fff, -1px -1px 0 #fff;
  }
  .pageID-top .topAbout_en:after {
    content: none;
  }
  .pageID-top .topAbout_body {
    margin: 20px 0 0;
    max-width: none;
    width: auto;
  }
  .pageID-top .topAbout_lead {
    font-size: 1.1428571429rem;
    text-shadow: 1px 1px 0 #fff, -1px 1px 0 #fff, 1px -1px 0 #fff, -1px -1px 0 #fff;
  }
  .pageID-top .topAbout_txt {
    margin: 20px 0 0;
    font-size: 0.9285714286rem;
    text-shadow: 1px 1px 0 #fff, -1px 1px 0 #fff, 1px -1px 0 #fff, -1px -1px 0 #fff;
  }
  .pageID-top .topAbout_btn {
    margin: 25px auto 0;
    width: auto;
  }
}
.pageID-top .topProject {
  /*  bnrs
  ----------------------------------------------------------------------*/
}
.pageID-top .topProject_inner {
  background: #ec6c00;
  width: calc(1400/1920 * 100vw);
  min-width: 670px;
  margin: 60px auto 0 0;
  padding-left: 20px;
  padding-top: min(80px, 5.8910162003vw);
  padding-bottom: min(80px, 5.8910162003vw);
}
.pageID-top .topProject_contents {
  max-width: 990px;
  margin: 0 0 0 auto;
  color: #fff;
}
.pageID-top .topProject_title {
  font-family: "Noto Serif JP", serif;
  font-weight: bold;
  line-height: 1;
  font-size: min(16px, 1.2549019608vw);
}
.pageID-top .topProject_en {
  margin: 0;
  font-family: "Noto Serif JP", serif;
  font-weight: bold;
  line-height: 1;
  font-size: min(74px, 5.8039215686vw);
  position: relative;
}
.pageID-top .topProject_en:after {
  content: "";
  display: block;
  max-width: 520px;
  width: 38.2916053019vw;
  height: 2px;
  background: #fff;
  position: absolute;
  top: 50%;
  right: 0;
  margin-top: -1px;
}
.pageID-top .topProject_body {
  padding-right: 20px;
}
.pageID-top .topProject_btn {
  margin-top: min(70px, 5.4901960784vw);
  max-width: 280px;
  width: 21.9607843137vw;
}
.pageID-top .topProject_bnrs {
  margin-top: min(80px, 5.8910162003vw);
  margin-right: calc(440/1920 * -100vw);
  margin-left: min(60px, 4.4182621502vw);
  display: flex;
  display: -webkit-flex;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  flex-direction: row;
}
.pageID-top .topProject_bnr {
  width: 50%;
  background: #fff;
}
.pageID-top .topProject_bnr > a {
  display: block;
  position: relative;
  text-decoration: none;
}
.pageID-top .topProject_bnrGg {
  margin: 0;
  width: 100%;
}
.pageID-top .topProject_bnrGg img {
  width: 100%;
}
.pageID-top .topProject_bnrBody {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  padding: 1.5625vw 2.0833333333vw;
  background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 60%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 60%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 60%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#00000000", endColorstr="#80000000",GradientType=0 );
  /* IE6-9 */
  color: #fff;
  display: flex;
  display: -webkit-flex;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.pageID-top .topProject_bnrName {
  margin: 0;
  width: 100%;
  font-size: min(30px, 1.5625vw);
  font-family: "Noto Serif JP", serif;
}
.pageID-top .topProject_bnrTxt {
  margin: 1.0416666667vw 1.0416666667vw 0 0;
  font-size: min(16px, 0.8333333333vw);
}
.pageID-top .topProject_bnrMore {
  margin: 1.0416666667vw 0 0 auto;
  font-size: min(16px, 0.8333333333vw);
  font-family: "Noto Serif JP", serif;
  display: flex;
  display: -webkit-flex;
  align-items: center;
}
.pageID-top .topProject_bnrMore:after {
  content: "";
  font-family: "icons";
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: min(40px, 2.0833333333vw);
  margin-left: 0.78125vw;
}
@media all and (-ms-high-contrast: none) {
  .pageID-top .topProject_inner {
    width: auto;
    padding: 80px 20px;
    width: calc(1400/1920 * 100vw);
    min-width: 520px;
  }
  .pageID-top .topProject_contents {
    margin: 0;
  }
  .pageID-top .topProject_title {
    font-size: 16px;
  }
  .pageID-top .topProject_en {
    font-size: 74px;
  }
  .pageID-top .topProject_en:after {
    width: 520px;
    right: -20px;
  }
  .pageID-top .topProject_btn {
    margin-top: 70px;
  }
  .pageID-top .topProject_bnrs {
    min-width: calc(1400px - 40px);
    width: calc(100vw - 60px);
    margin-top: 80px;
    margin-right: 0;
    margin-left: 0;
  }
  .pageID-top .topProject_bnrName {
    font-size: 30px;
  }
  .pageID-top .topProject_bnrTxt {
    font-size: 16px;
  }
  .pageID-top .topProject_bnrMore {
    font-size: 16px;
  }
  .pageID-top .topProject_bnrMore:after {
    font-size: 40px;
  }
}
@media screen and (max-width: 834px) {
  .pageID-top .topProject {
    /*  bnrs
    ----------------------------------------------------------------------*/
  }
  .pageID-top .topProject_inner {
    width: auto;
    min-width: 0;
    margin: 0;
    padding: 30px 10px;
  }
  .pageID-top .topProject_contents {
    width: auto;
    max-width: none;
  }
  .pageID-top .topProject_head {
    display: flex;
    display: -webkit-flex;
    align-items: flex-end;
  }
  .pageID-top .topProject_title {
    order: 1;
    margin: 0 0 0 0.5em;
    font-size: 1.1428571429rem;
    line-height: 1.2;
  }
  .pageID-top .topProject_en {
    order: 0;
    font-size: 2.1428571429rem;
  }
  .pageID-top .topProject_en:after {
    content: none;
  }
  .pageID-top .topProject_body {
    margin: 20px 0 0;
    max-width: none;
  }
  .pageID-top .topProject_body > :first-child {
    margin-top: 0;
  }
  .pageID-top .topProject_txt {
    margin: 20px 0 0;
    font-size: 0.9285714286rem;
  }
  .pageID-top .topProject_btn {
    margin: 25px auto 0;
    width: auto;
  }
  .pageID-top .topProject_bnrs {
    margin: 30px 0 0;
    display: block;
  }
  .pageID-top .topProject_bnrs > :first-child {
    margin-top: 0;
  }
  .pageID-top .topProject_bnr {
    width: auto;
  }
  .pageID-top .topProject_bnrGg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  .pageID-top .topProject_bnrGg img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  .pageID-top .topProject_bnrBody {
    position: relative;
    padding: 15% 10px 10px;
    display: block;
    background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 20%, rgba(0, 0, 0, 0.5) 60%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 20%, rgba(0, 0, 0, 0.5) 60%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 20%, rgba(0, 0, 0, 0.5) 60%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#00000000", endColorstr="#80000000",GradientType=0 );
    /* IE6-9 */
  }
  .pageID-top .topProject_bnrName {
    font-size: 1.1428571429rem;
  }
  .pageID-top .topProject_bnrTxt {
    font-size: 0.8571428571rem;
    line-height: 1.4;
  }
  .pageID-top .topProject_bnrMore {
    font-size: 0.9285714286rem;
    justify-content: flex-end;
  }
  .pageID-top .topProject_bnrMore:after {
    font-size: 1.4285714286rem;
    margin-left: 0.5em;
    line-height: 1;
  }
}
@media all and (-ms-high-contrast: none) and (max-width: 834px) {
  .pageID-top .topProject_bnrs {
    min-width: 0;
    width: auto;
  }
}
.pageID-top .topNews {
  margin: min(110px, 9.649122807vw) 0 0;
}
.pageID-top .topNews_head {
  padding: 0 20px min(25px, 2.1929824561vw);
  border-bottom: 1px solid #333;
}
.pageID-top .topNews_headInner {
  max-width: 1100px;
  margin: 0 auto;
  display: flex;
  display: -webkit-flex;
  align-items: flex-end;
}
.pageID-top .topNews_en {
  margin: 0;
  font-family: "Noto Serif JP", serif;
  font-weight: bold;
  line-height: 1;
  font-size: min(54px, 4.7368421053vw);
  position: relative;
}
.pageID-top .topNews_title {
  font-family: "Noto Serif JP", serif;
  font-weight: bold;
  line-height: 1;
  font-size: min(16px, 1.4035087719vw);
  margin-left: min(20px, 1.7543859649vw);
}
.pageID-top .topNews_body {
  max-width: 1140px;
  margin: 0 auto;
  padding: 0 20px;
}
.pageID-top .topNews_btn {
  margin-top: min(70px, 5.4901960784vw);
  max-width: 280px;
  width: 21.9607843137vw;
}
@media all and (-ms-high-contrast: none) {
  .pageID-top .topNews {
    margin: 110px 0 0;
  }
  .pageID-top .topNews_head {
    padding: 0 20px 25px;
  }
  .pageID-top .topNews_en {
    font-size: 54px;
  }
  .pageID-top .topNews_title {
    margin-left: 20px;
    font-size: 16px;
  }
  .pageID-top .topNews_btn {
    margin: 70px 0 0;
  }
}
@media screen and (max-width: 834px) {
  .pageID-top .topNews {
    margin: 40px 0 0;
  }
  .pageID-top .topNews_head {
    padding: 0 10px 10px;
  }
  .pageID-top .topNews_en {
    font-size: 2.1428571429rem;
  }
  .pageID-top .topNews_en:after {
    content: none;
  }
  .pageID-top .topNews_title {
    margin: 0 0 0 0.5em;
    font-size: 1.1428571429rem;
    line-height: 1.2;
  }
  .pageID-top .topNews_body {
    max-width: none;
    padding: 0 10px;
  }
  .pageID-top .topNews_btn {
    margin: 25px auto 0;
    width: auto;
  }
}
@media screen and (min-width: 835px) {
  .pageID-top .pages_cate {
    margin-left: min(20px, 1.7543859649vw);
  }
  .pageID-top .pages_title {
    margin-left: min(30px, 1.7543859649vw);
  }
}